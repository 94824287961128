@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* remove arrows input type='number' */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox arrows */
input[type='number'] {
  -moz-appearance: textfield;
}

/* ant design override  */
.ant-drawer-close {
  padding: 1.15rem;
  margin: 0.05rem;
}

.ant-btn {
  padding: 0 12px;
  width: auto;
  height: 31px;
}

.ant-menu-inline-collapsed {
  width: auto;
}

.ant-layout-sider,
.ant-layout-sider * {
  transition: none !important;
}

.ant-menu-item {
  padding-left: 1rem !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
}
.ant-image-preview-img {
  margin: auto;
}

/* Vehicle - Map */
.map-table td {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.gm-ui-hover-effect:focus {
  outline: none;
}

/* tailwind overrides antd design  */
svg {
  vertical-align: initial;
}

.ant-input::-webkit-input-placeholder,
.ant-select-selection-placeholder {
  color: #595959;
}
.ant-input::-moz-placeholder,
.ant-select-selection-placeholder {
  color: #595959;
}
.ant-input::-ms-placeholder,
.ant-select-selection-placeholder {
  color: #595959;
}
.ant-input::placeholder,
.ant-select-selection-placeholder {
  color: #595959;
}

th,
.ant-table-thead > tr > th,
.text-shadow {
  font-weight: normal;
  text-shadow: 0 0 0.1px #000;
}

/* controller status */
.approved,
.approved.ant-select-item-option-active {
  background-color: #6ee7b7 !important;
}
.disapproved,
.disapproved.ant-select-item-option-active {
  background-color: #fca5a5 !important;
}

.approved:hover,
.disapproved:hover {
  opacity: 0.9;
}

.dropdown-select-wrapper {
  width: 100%;
}

.dropdown-select-wrapper > div {
  width: 100%;
}

.dropdown-select-wrapper > label {
  width: 100%;
}

.dropdown-select-wrapper > div > div {
  width: 100%;
}

.ant-upload-wrapper > span > .ant-upload {
  display: block;
}

@media(max-width:380px) {
  .dropdown-select-wrapper .ant-select-selection-item {
   max-width: 250px;
  }
}

@media(max-width:330px) {
  .dropdown-select-wrapper .ant-select-selection-item {
   max-width: 200px;
  }
}

.ant-card-head-title {
  white-space: pre-line;
}

@media (max-width: 576px) {
  .ant-table-pagination > .ant-pagination-total-text {
    display: none;
  }
}

.dko-table {
  border-spacing: 0;
  border-collapse: separate;
  page-break-inside:auto;
  margin-bottom: 10px;
  table-layout: auto;
  width: 100%;
}

.dko-table td {
  border: 1px solid rgba(229, 231, 235, 1);
  padding: 2px 5px;
}

.dko-table td:first-of-type {
  width: 20px;
}

.dko-table td:nth-of-type(2) {
  width: 300px;
}

.dko-table-title {
  border: 1px solid rgba(229, 231, 235, 1);
  background: rgba(249, 250, 251, 1);
}

.dko-table-page-break {
  page-break-before: always;
  padding: 15px;
}